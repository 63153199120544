<template>
  <div
    ref="detail"
    v-loading="loading"
    element-loading-text="初始化组态，请稍后..."
    element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(255, 255, 255, 1)"
    background="#FFFFFF"
    id="app"
    :style="{ width: canWidth }"
    style="
      text-align: center;
      margin: 0 auto;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #f0eef5;
    "
  >
    <HMIDrawArea
    v-if="$store.state.openNumber"
      style="height: 700px; position: relative"
      :style="{ width: canWidth }"
      edit="false"
    ></HMIDrawArea>
    <!-- <HMIZoombar edit="false"></HMIZoombar> -->
    <!-- <HMIScrollbarH edit="false"></HMIScrollbarH>
    <HMIScrollbarV edit="false"></HMIScrollbarV> -->
  </div>
</template>
<script>
// 导入组件
import HMIPrj from "../util/draw/HMIProject.js";
import HMIInterface from "../util/draw/HMIInterface.js";
import HMIDataPool from "../util/draw/HMIDataPool.js";
import HMIPlanData from "../util/draw/HMIPlanData.js";
import HMIDrawArea from "../components/HMIDrawArea.vue";
// import HMIZoombar from "../components/HMIZoombar.vue";
import HMIScrollbarH from "../components/HMIScrollbarH.vue";
import HMIScrollbarV from "../components/HMIScrollbarV.vue";
import gWebSocket from "../util/draw/HMIWebSocket.js";
import gEchoData from "../util/draw/HMIEchoData";
import HMIDOMCtrlMG from "../util/draw/HMIDOMCtrlMG";
import HMIDraw from "../util/draw/HMIDraw.js";
import "@/assets/css/main.css";
let that;
// 当前组件属性
export default {
  props: ["canvasWidth"],
  // 组件名
  name: "App",
  data() {
    return {
     zoom: 1,
      canWidth: "",
    };
  },
  // props: ["projectData"],
  // 创建
  mounted() {
    // this.$store.commit('setopenNumber', false)
    // this.$nextTick(() => {
    //   this.$store.commit('setopenNumber', true)
    // })
    // that.$nextTick(() => {
    //   let htmlCollection  = document.getElementsByTagName('canvas')
    //   console.log(htmlCollection .item(0));
    // })
    this.zoom = 1 / document.body.style.zoom;
    window.addEventListener("resize", () => {
      this.zoom = 1 / document.body.style.zoom;
    });
    HMIDraw.settings.deviceZoom=this.zoom;
  },
  created: function () {
    that = this;
    // HMIDraw.settings.deviceZoom=this.zoom;
    if (this.canvasWidth) {
      this.canWidth = this.canvasWidth;
    } else {
      this.canWidth = "1280px";
    }
    // // 参数
    // let sToken, sTokenId, vplanId, vplatType;
    // if (Object.keys(this.$route.params).length > 0) {
    //   sToken = this.$route.params.token;
    //   sTokenId = this.$route.params.tokenId;
    //   vplanId = this.$route.params.planId;
    //   vplatType = this.$route.params.platType;

    //   this.$store.commit("setPId", vplanId);
    //   this.$store.commit("setToken", sToken);
    //   this.$store.commit("setTokenId", sTokenId);
    //   this.$store.commit("setPlatType", vplatType);
    // }

    if (!this.$store.state.projectId) {
      this.$store.commit("setLoading", false);
    }
    this.$store.commit("setIsEdit", false);
    // 登录成功状态
    HMIInterface.loginSuccess = this.loadSuccess;
    // 登录错误状态
    HMIInterface.loginErr = this.loadErr;
    // 执行读取通讯文件成功
    HMIInterface.readCommSuccess = this.readCommSuccess;
    // 设置planId
    HMIInterface.planID = this.$store.state.pId;
    // 直接登录设置Token
    let token=this.$route.query.token || this.$store.state.token
    HMIInterface.loginDirect(token);
    HMIInterface.paramsKey();

    // 打开与接收句柄
    gWebSocket.setRecevMsgHandle(this.recevServerMessage);
    gWebSocket.setOpenHandle(this.openHandle);
    if (!HMIDraw.editingMode) {
      window.addEventListener(
        "beforeunload",
        (e) => {
          HMIInterface.closeWs();
          that.$api.mainPage.close(that.$store.state.tokenId);
          return;
        },
        false
      );
    }
  },
  // 数据
  data: function () {
    return {
      // 登录画面
      loginRefresh: 0x100,
      // 是否显示
      loginVisible: true,
    };
  },
  // 快速计算缓存
  computed: {
    loading: function () {
      return this.$store.state.loading;
    },
  },
  // 函数
  methods: {
    // 刷新登录状态
    loadSuccess: function (vPrjData) {
      // web socket token
      // let sWebSocketToken = "fa14d031-1647-404a-8d90-8b4c1988f617";
      // 加载
      HMIPrj.vHMIPrjFunc.loadProject(vPrjData);
      // 当前项目中选中的页面
      HMIPrj.vHMIPrj.curPg = 0;
      //-----------------------------------------------------------------------
      // LEE 2022/04/25 检查是否为启动画面是否有效
      //
      if (HMIPrj.vHMIPrj.startPg) {
        // 当前项目中选中的页面
        HMIPrj.vHMIPrj.curPg = HMIPrj.vHMIPrj.startPg;
      }
      // 项目是否改变
      HMIPrj.vHMIPrj.modified = false;

      // 打开Web Socket
      // if (!HMIPrj.vHMIPrj.modified) {

      // }
    },
    // 刷新失败状态
    loadErr: function () {
      // 错误状态
    },
    // 读取通讯文件成功函数
    readCommSuccess: function (vCommData) {
      // 检查是否为空
      if (vCommData != "") {
        // 读取通讯文件成功
        // 处理分配数据  进行接口调用
        gEchoData.vCommData = vCommData;
        HMIDataPool.loadAllInerfaceData(HMIPrj.vHMIPrj.curPg);
      }
    },
    // 打开Web Socket
    openHandle: function () {
      // 发送
      gWebSocket.sendEvent("");
    },
    // 读取到消息
    recevServerMessage: function (vMsg) {
      // if (vMsg=="ping%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%") {
      //   return;
      // }
      // 检查字符是否有效
      if (vMsg) {
        let vAllData = JSON.parse(vMsg);
        // let data= JSON.parse(vMsg);
        //  let vAllData = [...Data]
        // console.log(vAllData);  //获取组态界面数据
        let vNewData = {};
        let vItem = null;
        let vParamId = null;
        let vParamValue = null;
        // 检查数量
        for (let idx = 0; idx < vAllData.length; idx++) {
          // 获取当前项目
          vItem = vAllData[idx];
          // 检查当前项目是否有效
          if (vItem) {
            // 获取Id
            vParamId = vItem.paramId;
            // 获取当前值
            vParamValue = vItem.value;
            // 输出
            HMIPrj.vHMIPrj.allPg.forEach((pg) => {
              pg.ctrls.forEach((ele) => {
                if (ele.param && ele.param[0]) {
                  // 检查是否为内部数据
                  if (
                    ele.param[0] ===
                    HMIPlanData.PlanDataInsideParamKeys.insideParamValue
                  ) {
                    // 内部数据不需要处理
                    return;
                  }
                  // 设置数据
                  if (ele.param[0].length > 0) {
                    ele.dataValue = vParamValue;
                  }

                  if (
                    vItem.paramId === ele.param[2] &&
                    vItem.gatewayId === ele.param[0] &&
                    vItem.soId === ele.param[1]
                  ) {
                    ele.dataValue = vParamValue;

                    ele["paramState"] = vItem.state;
                  }
                } else {
                  if (vItem.paramId === ele.param) {
                    ele.dataValue = vParamValue;
                  }
                  if (ele.dataTable) {
                    ele.dataTable.forEach((item) => {
                      if (
                        vItem.paramId == item.param[2] &&
                        vItem.gatewayId == item.param[0] &&
                        vItem.soId == item.param[1]
                      ) {
                        HMIDraw.valueNew = true;
                        item["value"] = vParamValue;
                        item["paramState"] = vItem.state;
                      }
                    });
                  }
                }
              });
            });
          }
          vNewData[vParamId] = vItem;
        }
        gEchoData.wsData = vNewData;
        gEchoData.status.wsData = new Date().getTime();
      }
    },
    writeInWebsocketData: function (data) {
      let newVHMIPrj = HMIPrj.vHMIPrj;
      newVHMIPrj;
      data;
    },
  },
  beforeDestroy: function () {
    gWebSocket.closeWebSocket();
    this.$store.commit("setLoading", true);
    HMIDOMCtrlMG.allDisplayDOMCtrls = [];
    HMIDOMCtrlMG.domCtrls = true;
  },
  components: {
    HMIDrawArea,
    // HMIZoombar,
    HMIScrollbarH,
    HMIScrollbarV,
  },
};
</script>
