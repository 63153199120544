<template>
  <el-card class="iot-person public-page-style iot-search-header">
    <div class="title">
      <el-row>
        <el-col :span="5">状态查询：<el-select
        v-model="pjData.projectState"
        @change="getProjectPage()"
        placeholder="请选择"
        size="small"
      >
        <el-option
          v-for="item in options"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select></el-col>
        <el-col :span="5">方案查询：<el-select
        v-model="pjData.planId"
        @focus="getPlanList()"
        @change="pjData.current=1,getProjectPage()"
        placeholder="请选择"
        clearable
        size="small"

      >
        <el-option
          v-for="item in planList"
          :key="item.planId"
          :label="item.planName"
          :value="item.planId"
        >
        </el-option>
      </el-select></el-col>
      <el-col :span="13">项目查询：
       <el-input v-model="pjData.projectName" placeholder="请输入SN或项目名称查询" clearable >
        <i slot="suffix" class="el-input__icon el-icon-search" @click="pjData.current=1,getProjectPage()"></i>
       </el-input>
     </el-col>
        <el-col :span="1"> <div @click="$router.go(-1)" style="cursor: pointer">
        <svg
          t="1645058599105"
          class="icon"
          viewBox="0 0 1024 1024"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          p-id="2046"
          width="16"
          height="16"
        >
          <path
            d="M512 456.310154L94.247385 38.557538a39.542154 39.542154 0 0 0-55.689847 0 39.266462 39.266462 0 0 0 0 55.689847L456.310154 512 38.557538 929.752615a39.542154 39.542154 0 0 0 0 55.689847 39.266462 39.266462 0 0 0 55.689847 0L512 567.689846l417.752615 417.752616c15.163077 15.163077 40.290462 15.36 55.689847 0a39.266462 39.266462 0 0 0 0-55.689847L567.689846 512 985.442462 94.247385a39.542154 39.542154 0 0 0 0-55.689847 39.266462 39.266462 0 0 0-55.689847 0L512 456.310154z"
            p-id="2047"
          ></path>
        </svg>
      </div></el-col>
      </el-row>
    </div>
    <div class="info-body">
      <div class="project-body-item">
        <div
          class="project-item"
          v-for="item in projectPage"
          :key="item.projectId"
          @click="openProject(item.projectId, item.planId, item)"
        >
          <!-- <div class="project-item"> -->
          <div class="project-item-image">
            <img
              :src="
                constant.OSS_URL +
                constant.PROJECT_IMG +
                item.projectImg
              "
            />
          </div>
          <div class="project-item-words">
            <h4>{{ item.projectName }}</h4>
            <!-- <div class="remark" :title="item.remark">
                  {{ item.remark }}
                </div> -->
            <div>
              <el-tag
                size="small"
                v-if="item.projectState == 1 && item.serverType == 0"
                type="success"
                >正常</el-tag
              >
              <el-tag
                size="small"
                v-if="item.projectState == 2 && item.serverType == 0"
                type="info"
                >离线</el-tag
              >
              <el-tag
                type="danger"
                size="small"
                v-if="item.projectState == 3 && item.serverType == 0"
                >报警</el-tag
              >
              <el-tag type="success" size="small" v-if="item.serverType == 1"
                >三方</el-tag
              >
              <el-tag
                size="small"
                v-if="item.projectState == 4 && item.serverType == 0"
                type="warning"
                >故障</el-tag
              >
              <!-- <el-tag
                style="margin-left: 20px"
                size="small"
                @click.stop="editProject(item.projectId)"
                v-show="
                  $store.state.permissionArr.indexOf('project:update') > -1
                "
                >修改</el-tag
              > -->

              <el-tag
                style="margin-left: 10px"
                size="small"
                type="info"
                @click.stop="delProject(item.projectId)"
                v-show="
                  $store.state.permissionArr.indexOf('project:delete') > -1
                "
                >解绑</el-tag
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="page">
      <el-pagination
        @size-change="projectSizeChange"
        @current-change="projectCurrentChange"
        :current-page="pjData.current"
        :page-size="pjData.size"
        layout="total, prev, pager, next, jumper"
        :total="pjData.total"
        class="pagination"
      ></el-pagination>
    </div>
    <!-- 组态页面弹框 -->
    <el-dialog
      v-if="pShow"
      class="detailDialog"
      :visible.sync="pShow"
      :width="getWidth()"
      :show-close="false"
      :lock-scroll="true"
      top="3%"
      :destroy-on-close="true"
      @closed="closed"
      :before-close="closeDialog"
    >
      <template slot="title">
        <p>
          <img
            v-if="vStatus == 1"
            title="加速上传中..."
            style="float: left; width: 25px; height: 25px"
            src="../assets/v.gif"
          />
          <img
            v-if="vStatus == 0"
            title="停止加速"
            style="float: left; width: 25px; height: 25px"
            src="../assets/v1.png"
            @click="openV(projectDetail.projectId)"
          />
          <span style="float: left; margin-top: -4px">{{ currentPName }}</span>
          <!-- <span
              style="cursor: pointer"
              
              >{{ vStatus == 0 ? "未加速" : "加速上传中..." }}</span
            > -->
          <!-- <div v-if="cameraList.length!=0"> -->
          <el-dropdown
            style="float: right"
            v-for="item in cameraList"
            :key="item.ysId"
          >
            <img
              style=""
              :src="constant.SYSTEM_IMG + 'menu1.png'"
              @click="monitorClick(item)"
            />
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item
                >摄像头名称：{{ item.ysName }}
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>

          <el-dropdown style="float: right">
            <img
              style="margin-right: 10px"
              :src="constant.SYSTEM_IMG + 'menu5.png'"
            />
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item
                >项目名称：{{ projectDetail.projectName }}</el-dropdown-item
              >
              <el-dropdown-item
                >所属方案：{{ projectDetail.planName }}</el-dropdown-item
              >
              <el-dropdown-item
                  >所属用户：{{ projectDetail.fullName }}</el-dropdown-item
                >
                <el-dropdown-item
                  >所属项目组：{{ projectDetail.projectGroupName }}</el-dropdown-item
                >
              <el-dropdown-item style="position: relative"
                >触发条件：
                <el-tag
                  style="position: absolute; right: 25px; top: 2px"
                  @click="passVisible = true"
                  plain
                  >编辑</el-tag
                >
                <!-- 输入密码 -->
                <el-dialog
                  title="输入密码"
                  width="15%"
                  :visible.sync="passVisible"
                  append-to-body
                  top="15%"
                >
                  <el-input @keyup.enter.native="check()" v-model="password"></el-input>
                  <div slot="footer" class="dialog-footer">
                    <el-button type="primary" @click="check()">确 定</el-button>
                    <el-button @click="passVisible = false">取 消</el-button>
                  </div>
                </el-dialog>
                <!-- 触发条件列表弹框 -->
                <el-dialog
                  title="触发条件"
                  width="60%"
                  :visible.sync="triggerVisible"
                  :before-close="valueConfig"
                  append-to-body
                  top="6%"
                >
                  <div style="width: 100%; height: 465px; overflow: auto">
                    <el-dialog
                      width="15%"
                      :visible.sync="valueVisible"
                      :before-close="valueConfig"
                      append-to-body
                      top="15%"
                    >
                      <el-input v-model="triggerValue"></el-input>
                      <div slot="footer" class="dialog-footer">
                        <el-button type="primary" @click="proAlarm()"
                          >确 定</el-button
                        >
                        <el-button @click="valueVisible = false"
                          >取 消</el-button
                        >
                      </div>
                    </el-dialog>
                    <el-table :data="triggerList" stripe border="">
                      <el-table-column
                        prop="alarmName"
                        label="触发器名称"
                        width="140"
                      ></el-table-column>
                      <!-- <el-table-column
                          prop="dtuSn"
                          label="触发时间"
                          width="200"
                        ></el-table-column> -->
                      <el-table-column label="触发条件">
                        <template slot-scope="scope">
                          <div v-if="scope.row.conditionTagShow != 'in'">
                            <span>{{ scope.row.paramName }}</span>
                            <span>{{ scope.row.conditionTagShow }}</span>
                            <span
                              v-if="scope.row.atype == 1"
                              @click="setValue(scope.row, 'aValue')"
                              class="editNum"
                              >{{ scope.row.aValue }}</span
                            >
                            <span v-else>{{ scope.row.aParamName }}</span>
                            <span v-if="scope.row.aAddSubt">{{
                              scope.row.aAddSubt
                            }}</span>
                            <span
                              v-if="scope.row.xValue&&scope.row.atype!=1"
                              class="editNum"
                              @click="setValue(scope.row, 'xValue')"
                              >{{ scope.row.xValue }}</span
                            >
                            <span v-if="scope.row.sValue != 0"
                              >({{ scope.row.sParamName }})</span
                            >
                          </div>
                          <div v-else>
                            <span
                              v-if="scope.row.atype == 1"
                              @click="setValue(scope.row, 'aValue')"
                              class="editNum"
                              >{{ scope.row.aValue }}</span
                            >
                            <span v-else>{{ scope.row.aParamName }}</span>
                            <span>&lt;</span>
                            <span>{{ scope.row.paramName }}</span>
                            <span v-if="scope.row.aAddSubt">&lt;</span>
                            <span
                              v-if="scope.row.xValue"
                              class="editNum"
                              @click="setValue(scope.row, 'xValue')"
                              >{{ scope.row.xValue }}</span
                            >
                          </div>
                        </template>
                      </el-table-column>
                      <el-table-column label="触发方式">
                        <template slot-scope="scope">
                          <span v-if="scope.row.forward == 0">报警</span>
                          <span v-if="scope.row.forward == 1">报警联动</span>
                          <span v-if="scope.row.forward == 2">联动</span>
                        </template>
                      </el-table-column>
                      <el-table-column
                        prop="aInterval"
                        label="触发间隔"
                        width="160px"
                      ></el-table-column>
                      <el-table-column label="状态" width="150" fixed="right">
                        <template slot-scope="scope">
                          <span style="cursor: pointer;"
                            :class="{ select: scope.row.disType == 0 }"
                            @click="changeSelect(0, scope.row)"
                            >启用</span
                          >
                          <span> / </span>
                          <span style="cursor: pointer;"
                            :class="{ select: scope.row.disType == 1 }"
                            @click="changeSelect(1, scope.row)"
                            >禁用</span
                          >
                        </template>
                      </el-table-column>
                    </el-table>
                  </div>
                  <div
                    style="
                      width: 100%;
                      display: flex;
                      justify-content: flex-end;
                    "
                  >
                    <el-pagination
                      background
                      @size-change="triggerSizeChange"
                      @current-change="triggerCurrentChange"
                      :current-page="triggerPage.current"
                      :page-sizes="[10, 20, 30, 40]"
                      :total="triggerPage.total"
                      :page-size="triggerPage.size"
                      style=""
                      layout="total, sizes, prev, pager, next, jumper"
                      @prev-click="
                        () => {
                          $set(triggerPage, 'current', triggerPage.current - 1);
                          getTriggerList();
                        }
                      "
                      @next-click="
                        () => {
                          $set(triggerPage, 'current', triggerPage.current + 1);
                          getTriggerList();
                        }
                      "
                    >
                    </el-pagination>
                  </div>

                  <div slot="footer" class="dialog-footer">
                    <el-button type="primary" @click="upProAlarm"
                      >确 定</el-button
                    >
                    <el-button @click="triggerVisible = false">取 消</el-button>
                  </div>
                </el-dialog>
              </el-dropdown-item>
              <el-dropdown-item
                >备注：
                <p style="max-width: 200px">
                  {{ projectDetail.remark }}
                </p></el-dropdown-item
              >
              <el-dropdown-item>绑定时间：{{ projectDetail.createTime }}</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
          <el-dropdown style="float: right" v-if="0 == 1">
            <img :src="constant.SYSTEM_IMG + 'menu1.png'" />
            <el-dropdown-menu slot="dropdown"> </el-dropdown-menu>
          </el-dropdown>
          <el-dropdown style="float: right" :hide-on-click="false">
            <img
              v-if="projectState != 2"
              :src="
                constant.SYSTEM_IMG +
                'menu' +
                (signal > 20 ? '2' : signal > 15 ? '3' : '4') +
                '.png'
              "
            />
            <img
              v-else-if="projectState == 2"
              :src="constant.SYSTEM_IMG + 'menu6.png'"
            />
            <el-dropdown-menu slot="dropdown"
              ><div class="dtus">
                <el-carousel
                  indicator-position="outside"
                  height="240px"
                  :autoplay="false"
                >
                     <el-carousel-item
                      v-for="(item, index) in projectDetail.dtus"
                      :key="index"
                    >
                      <div class="dtus_body">
                        <el-dropdown-item
                          :divided="index == 0 ? false : true"
                          style="display: flex; justify-content: space-between"
                        >
                          <div>{{ item.gatewayName }}</div>
                          <div v-if="item.dtuState == 1">
                            <el-tag type="success" plain>在线</el-tag>
                          </div>
                          <div v-else-if="item.dtuState == 2">
                            <el-tag type="info" plain>离线</el-tag>
                          </div>
                          <div v-else-if="item.dtuState == 3">
                            <el-tag type="danger" plain>告警</el-tag>
                          </div>
                        </el-dropdown-item>
                        <el-dropdown-item
                          style="display: flex; justify-content: space-between"
                        >
                          <div>信号：{{ signal }}</div>
                          <el-button
                            size="mini"
                            type="success"
                            style="height: 29px; margin: auto 0"
                            @click="getSignal(item, projectDetail)"
                            plain
                            >历史曲线</el-button
                          ></el-dropdown-item
                        >
                        <el-dropdown-item
                          >序列号：{{ item.dtuSn }}</el-dropdown-item
                        >
                        <el-dropdown-item
                          >卡号：{{ item.ccid }}</el-dropdown-item
                        >
                        <el-dropdown-item style="position: relative"
                          >剩余流量：{{ index | getLeft }}
                          <div class="btn">
                            <el-button
                              v-if="simVisible"
                              size="mini"
                              type="success"
                              @click="getSIM(item, index)"
                              plain
                              >流量查询</el-button
                            >
                            <el-button
                              v-else
                              size="mini"
                              type="success"
                              icon="el-icon-loading"
                              plain
                            ></el-button></div
                        ></el-dropdown-item
                        >
                        <el-dropdown-item
                          >SIM卡状态：{{ index | getCardStatus }}
                          
                        
                        </el-dropdown-item>
                         <el-dropdown-item
                          >到期时间：{{ index | getValiddate }}</el-dropdown-item
                        >
                      </div>
                    </el-carousel-item>
                </el-carousel>
              </div>
            </el-dropdown-menu>
          </el-dropdown>
          <span :style="project.style" class="iot-project-status">{{
            project.content
          }}</span>
           <span class="iot-project-status" style="color:black;font-size: 20px;margin-right: 35px;">{{currentTime }}</span>
        </p>
      </template>
      <HMIExecute
        :class="{ filterCss: projectState == 2 }"
        v-if="pShow"
        :key="hmiKey"
        :canvasWidth="canvasWidth"
      ></HMIExecute>
       <el-dialog
          v-if="signalVisible"
          class="detailDialog"
          :visible.sync="signalVisible"
          width="80%"
          :show-close="false"
          :lock-scroll="true"
          top="7%"
          append-to-body
        >
          <div>
            <div class="dtuSignalTitle">
              <span style="padding-right: 20px">选择日期:</span>
              <el-date-picker
                v-model="projectFrom.date"
                type="date"
                size="mini"
                :picker-options="pickerOptions"
                @change="setSignalHistory"
                value-format="yyyy-MM-dd"
                placeholder="选择日期"
              >
              </el-date-picker>
            </div>
            <div id="dtuSignal" ref="dtuSignal"></div>
          </div>

          <span slot="footer" class="dialog-footer">
            <el-button
              size="mini"
              type="primary"
              plain
              @click="signalVisible = false"
              >关闭</el-button
            >
          </span>
        </el-dialog>
      <span slot="footer" class="dialog-footer">
        <el-button size="mini" type="primary" plain @click="closeDialog"
          >关闭</el-button
        >
      </span>
    </el-dialog>
    <!-- 第三方信息 -->
    <el-dialog
        :show-close="false"
        :visible.sync="details"
        :destroy-on-close="false"
        :close-on-click-modal="true"
        :before-close="closeBind"
        top="3%"
        class="iot-dialog"
        width="50%"
      >
        <!-- <template slot="label">
          <i class="iconfont" style="color: rgb(82, 159, 211)">&#xe607;</i>
          <span>{{ item.gatewayName }}</span>
        </template>
        <el-input
          size="mini"
          v-model="projectDetails.vos[item.gatewayId]"
          placeholder="请输入网关SN"
        ></el-input>-->
        <el-form ref="form" :model="projectDetails" label-width="150px">
          <el-form-item>
            <template slot="label">
              <i class="iconfont" style="color: rgb(82, 159, 211)">&#xe607;</i>
              <span>所属方案</span>
            </template>
            <el-input
              size="mini"
              v-model="projectDetails.planName"
              placeholder="所属方案"
              readonly
            ></el-input>
          </el-form-item>
          <div v-for="(item, index) in projectDetails.dtus" :key="index">
            <el-form-item>
              <template slot="label">
                <i class="iconfont" style="color: rgb(82, 159, 211)">&#xe607;</i
                ><span> 所属网关 </span>
              </template>
              <el-input
                size="mini"
                v-model="item.gatewayName"
                placeholder="网关SN"
                readonly
              ></el-input>

              <span> 序列号 </span>
              <el-input
                size="mini"
                v-model="item.dtuSn"
                placeholder="网关SN"
                readonly
              ></el-input>
            </el-form-item>
          </div>
          <el-form-item>
            <template slot="label">
              <i class="iconfont" style="color: rgb(82, 159, 211)">&#xe607;</i>
              <span>产品ID</span>
            </template>
            <el-input
              size="mini"
              v-model="projectDetails.productId"
              placeholder="产品ID"
              readonly
            ></el-input>
          </el-form-item>
          <el-form-item>
            <template slot="label">
              <i class="iconfont" style="color: rgb(82, 159, 211)">&#xe607;</i>
              <span>设备ID</span>
            </template>
            <el-input
              size="mini"
              v-model="projectDetails.thirdSn"
              placeholder="设备ID"
              readonly
            ></el-input>
          </el-form-item>
          <el-form-item>
            <template slot="label">
              <i class="iconfont" style="color: rgb(82, 159, 211)">&#xe607;</i>
              <span>设备密钥</span>
            </template>
            <el-input
              size="mini"
              v-model="projectDetails.authInfo"
              placeholder="设备密钥"
              readonly
            ></el-input>
          </el-form-item>
          <el-form-item label="发布主题" prop="topicPub">
            <el-input
              readonly
              size="mini"
              v-model="projectDetails.topicPub"
              autocomplete="off"
            ></el-input>
          </el-form-item>
          <el-form-item label="发布质量" prop="pubQos">
            <el-radio-group readonly v-model="projectDetails.pubQos">
              <el-radio
                v-for="(item, index) in radio"
                :key="index"
                :label="item.value"
                >{{ item.names }}</el-radio
              >
              <!-- <el-radio :label="1">备选项</el-radio>
            <el-radio :label="2">备选项</el-radio> -->
            </el-radio-group>
          </el-form-item>

          <el-form-item label="订阅主题">
            <el-input
              readonly
              size="mini"
              v-model="projectDetails.topicSub"
              autocomplete="off"
            ></el-input>
          </el-form-item>
          <el-form-item label="订阅质量">
            <el-radio-group readonly v-model="projectDetails.subQos">
              <el-radio
                v-for="(item, index) in radio"
                :key="index"
                :label="item.value"
                >{{ item.names }}</el-radio
              >
              <!-- <el-radio :label="1">备选项</el-radio>
            <el-radio :label="2">备选项</el-radio> -->
            </el-radio-group>
          </el-form-item>
        </el-form>
        <div class="servers">
          <span>第三方设备无法在此平台查看状态，默认为离线状态</span>
        </div>
        <div slot="footer" class="dialog-footer">
          <el-button type="primary" plain size="mini" @click="details = false"
            >取消</el-button
          >
        </div>
      </el-dialog>
  </el-card>
</template>

<script>
import * as echarts from "echarts";
let that;
import { visitUrl } from "@/util/global.js";
import { PROJECT_IMG, OSS_URL, SYSTEM_IMG } from "../util/constant";
import HMIExecute from "./HMIExecute.vue";
export default {
  components: { HMIExecute },
  data() {
    return {
      planList:[],
      planId:'',
      currentTime:'',
      details:false,
      checkItem:{},
      putList: [],
      projectState: "",
      passVisible: false,
      password: "",
      alarmProjectId: "",
      valueVisible: false,
      triggerValue: "",
      valueName: "",
      triggerPage: {
        size: 10,
        current: 1,
        planId: "",
        projectId: "",
        total: 0,
      },
      triggerVisible: false,
      triggerList: [],
      maxDate: "",
      projectFrom: {
        projectId: "",
        dtuId: "",
        date: "",
      },
      signalVisible: false,
      projectPage: [],
      projectDetails: {},
      constant: { PROJECT_IMG, OSS_URL, SYSTEM_IMG },
      cameraList: [],
      pjData: {
        planId:"",
        projectState: 0,
        projectName: "",
        size: 20,
        current: 1,
        total: 0,
      },
      pShow: false,
      vStatus: 0,
      signal: 0,
      simVisible: true,
      canvasWidth: "1280px",
      options: [
        { value: "0", label: "全部" },
        { value: "1", label: "在线" },
        { value: "2", label: "离线" },
        { value: "3", label: "报警" },
        { value: "4", label: "故障" },
        { value: "5", label: "正常设备" },
      ],
       pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
      },
        getHistory(data, date, ref) {
        let option = {
          tooltip: {
            trigger: "axis",
          },
          toolbox: {
            feature: {
              saveAsImage: {},
            },
            right: 20,
            top: 20,
          },
          legend: {
            right: 0,
            top: "50%",
            orient: "vertical",
          },
          grid: {
            y: 50,
            y2: 50,
            x: 100,
            x2: 100,
          },
          xAxis: {
            type: "category",
            boundaryGap: false,
            data: date,
          },
          yAxis: {
            type: "value",
            nameLocation: "end",
            boundaryGap: [0, 0],
          },
          dataZoom: [
            {
              type: "inside",
              start: 0,
              end: 100,
            },
          ],
          series: [
            {
              name: data.name,
              type: "line",
              smooth: true,
              symbol: "none",
              data: data.data,
            },
          ],
        };
        var chartDom = document.getElementById(ref);
        let myChart = echarts.init(chartDom);
        myChart.clear();
        myChart.setOption(option);
      },
       radio: [
        {
          value: 0,
          names: "至多一次",
        },
      ],
    };
  },
  filters: {
    getLeft(index) {
      return that.simform[index].left;
    },
    getCardStatus(index) {
      return that.simform[index].cardStatus;
    }, 
     getValiddate(index){
      return that.simform[index].validdate;
    }
  },
  mounted() {
    that = this;
    if (that.$route.query.projectState) {
      that.pjData.projectState = that.$route.query.projectState;
    }
    that.getProjectPage();
    this.currentTime=new Date().toLocaleDateString()
  },
  methods: {
    getPlanList() {
      that.$api.plan.getPlanList().then((response) => {
        if (200 == response.code) {
          that.planList = response.data;
        }
      });
    },
    // editProject(pjId) {
    //   that.$api.projectGroup.getProject(pjId).then((response) => {
    //     if (response.code == 200) {
    //       that.eidtForm = response.data;
    //       that.bindingDialog = true;
    //       that.isCreate = false;
    //       that.formTitle = "修改项目";
    //     }
    //   });
    // },
    delProject(pjId) {
      that
        .$confirm("解绑后历史数据会被清空，谨慎操作！", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
        .then(() => {
          that.$api.project.delProject(pjId).then((response) => {
            if (response.code == 200) {
              that.$message({
                type: "success",
                message: "提交成功",
              });
              that.getProjectPage(that.pjData);
            }
          });
        });
    },
    closeBind(done) {
      done();
    },

    close(done) {
      this.$confirm("确认关闭？")
        .then((_) => {
          done();
        })
        .catch((_) => {});
    },
    //校验密码是否正确
    check() {
      if (that.password.toLowerCase() == "china") {
        that.putList = [];
        that.getTrigger();
        that.passVisible = false;
      } else {
        that.passVisible = false;
      }
      that.password = "";
    },
    // 当前是否启用
    changeSelect(value, row) {
      let a = 0;
       that.triggerList.forEach((item) => {
        if (row.alarmProjectId == item.alarmProjectId) {
          item.disType = value;
        }
      });
      that.putList.forEach((item) => {
        if (row.alarmProjectId == item.alarmProjectId) {
          a = 1;
          item.disType = value;
        }
      });
      if (a == 0) {
        let data = {
          disType: value,
          alarmProjectId: row.alarmProjectId,
        };
        that.putList.push(data);
      }

      // that.upProAlarm();
    },
    // 获取要修改的数据
    setValue(row, name) {
      that.checkItem=row
      that.triggerValue = row[name];
      that.valueName = name;
      that.alarmProjectId = row.alarmProjectId;
      that.valueVisible = true;
    },
    triggerSizeChange(val) {
      that.$set(that.triggerPage, "size", val);
      that.$set(that.triggerPage, "current", 1);
      that.getTriggerList();
    },
    triggerCurrentChange(val) {
      that.$set(that.triggerPage, "current", val);
      that.getTriggerList();
    },
    valueConfig(done) {
      // this.$confirm("确认关闭？")
      //   .then((_) => {
      //     done();
      //   })
      //   .catch((_) => {});
    },
    //发送更新配置请求
    updateConfig() {
      let data = {
        planId: that.projectDetail.planId,
        projectId: that.projectDetail.projectId,
      };
      that.$api.project.updateConfig(data).then((res) => {
        if (res.code == 200) {
          that.triggerVisible = false;
        }
      });
    },
    proAlarm() {
      let data;
       let e=that.triggerValue;
      if (that.checkItem.paramTypeId==3) {
        for (let i = 0; i < that.checkItem.typeValue; i++) {
          e=e*10
        }
        if (e%1>0) {
          that.$message({
            type: "error",
            message: `小数最多保留${that.checkItem.typeValue}位！`,
          })
          return
      }
      } 
      else{
         if (e%1>0) {
          that.$message({
            type: "error",
            message: `只能输入整数！`,
          })
          return
      }
      }
       
      that.triggerList.forEach((element) => {
        if (element.alarmProjectId == that.alarmProjectId) {
        
          element[that.valueName] = that.triggerValue;
     
        }
      });
      let a = 0;
      that.putList.forEach((item) => {
        if (that.alarmProjectId == item.alarmProjectId) {
          a = 1;
          item[that.valueName] = that.triggerValue;
        }
      });
        if (a == 0) {
          if (that.valueName == "aValue") {
            data = {
              aValue: that.triggerValue,
              alarmProjectId: that.alarmProjectId,
            };
          } else if (that.valueName == "xValue") {
            data = {
              xValue: that.triggerValue,
              alarmProjectId: that.alarmProjectId,
            };
          }
          that.putList.push(data);
        }

      that.valueVisible = false;
    },
    upProAlarm() {
      that.$api.project.proAlarm(that.putList).then((res) => {
        if (res.code == 200) {
          that.updateConfig();
          that.getTriggerList();
        }
      });
    },
    // 获取触发条件列表弹框
    getTrigger() {
      that.triggerPage.planId = that.projectDetail.planId;
      that.triggerPage.projectId = that.projectDetail.projectId;
      that.getTriggerList();
      that.triggerVisible = true;
    },
    // 拉取告警列表
    getTriggerList() {
      that.$api.project.getAlarmPage(that.triggerPage).then((res) => {
        if (res.code == 200) {
          that.triggerList = res.data.records;
          that.triggerPage.total=res.data.total;
        }
      });
    },
    getProjectPage() {
      that.$api.project.getUserPage(that.pjData).then((res) => {
        if (200 == res.code) {
          that.projectPage = res.data.records;
          that.pjData.total = res.data.total;
        }
      });
    },
    //打开组态页面
    openProject: function (projectId, planId, item) {
      this.$store.commit("setProjectId", projectId);
      this.$store.commit("setPId", planId);
      this.$store.commit("setPlatType", "pc");

      if (item.serverType == 0) {
        that.simform = [];
        that.getProjectDetail(projectId);
        that.getCameraList(projectId);
      } else if (item.serverType == 1) {
        this.details = true;
        this.$api.project.getProject(item.projectId).then((res) => {
          res.data.dtus=[res.data.dtuInfo]
          this.projectDetails = res.data;
        });
      }
    },
    closeDialog: function () {
      that.pShow = false;
      window.clearInterval(that.itv);
      that.itv = null;
      that.getProjectPage();
    },
    closed: function () {
      that.$store.commit("setLoading", true);
      window.clearInterval(that.itv);
      that.itv = null;
      // that.closeWebSocket(that.projectData.projectId);
      // gWebSocket.closeWebSocket();
    },
    getProjectDetail: function (projectId) {
      const loading = this.$loading({
          lock: true,
          text: '加载中，请稍后...',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        });
      that.$api.projectGroup.getProject(projectId).then((response) => {
        loading.close()
        if (200 == response.code) {
          that.projectDetail = response.data;
          this.$set( that.projectDetail,'dtus',[response.data.dtuInfo])
          that.getStatus(that.projectDetail.projectState);
          that.currentPName = that.projectDetail.projectName;
          that.hmiKey = new Date().getTime();
          that.pShow = true;
          for (let i = 0; i < response.data.dtus.length; i++) {
              let obj = { left: "**", cardStatus: "**",validdate : "**",};
            that.simform.push(obj);
          }
          that.openV(projectId);
          that.getV(projectId);
            clearInterval(that.itv);
           that.itv=null
          that.itv = setInterval(function () {
            that.getV(projectId);
          }, 10000);
        }
      });
    },
    getV: function (projectId) {
      that.$api.projectGroup.checkV(projectId).then((response) => {
        if (200 == response.code) {
          that.vStatus = response.data.fastState;
          that.projectState = response.data.projectState;
          that.signal = response.data.signal;
          that.getStatus(response.data.projectState);
        }
      });
    },
    openV: function (projectId) {
      that.$api.projectGroup.openV(projectId).then((response) => {
        if (200 == response.code) {
          // that.vStatus = response.data;
          that.getV(projectId);
        }
      });
    },

    getCameraList(projectId) {
      that.$api.camera.getCamera(projectId).then((res) => {
        that.cameraList = res.data;
      });
    },
    getStatus: function (projectState) {
      let obj = {
        style: null,
        content: null,
      };
      switch (projectState) {
        case 1:
          obj = {
            style: { "background-color": "#1eaa39" },
            content: "正常",
          };
          break;
        case 2:
          obj = {
            style: { "background-color": "rgba(180,180,181)" },
            content: "离线",
          };
          break;
        case 3:
          obj = {
            style: { "background-color": "#e50012" },
            content: "报警",
          };
          break;
        case 4:
          obj = {
            style: { "background-color": "#f29500" },
            content: "故障",
          };
          break;
        default:
          break;
      }

      that.project = obj;
    },
    getWidth: function () {
      return 71 + "%";
    },
    getSIM(item, index) {
      this.simVisible = false;
      that.$api.projectGroup.getMobile(item).then((res) => {
        [that.simform[index]] = [res.data];
        this.simVisible = true;
      });
    },
       getSignal(item, projectDetail) {
      that.projectFrom.projectId = projectDetail.projectId;
      that.projectFrom.dtuId = item.dtuId;
      let date = new Date();
      let month =
        date.getMonth() + 1 < 10
          ? "0" + (date.getMonth() + 1)
          : date.getMonth() + 1;
      let dates = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
      that.maxDate = date.getFullYear() + "-" + month + "-" + dates;
      that.projectFrom.date = date.getFullYear() + "-" + month + "-" + dates;
      that.signalVisible = true;
      that.setSignalHistory();
    },
    setSignalHistory() {
      that.$api.project.getCsqHistoryData(that.projectFrom).then((res) => {
        if (res.code == 200) {
          that.getHistory(res.data[0].data[0], res.data[0].date, "dtuSignal");
        }
      });
    },

    // 打开摄像头页面
    monitorClick: function (obj) {
      window.open(
        visitUrl +
          "#/openCamera?deviceSerial=" +
          obj.deviceSerial +
          "&validateCode=" +
          obj.validateCode +
          "&ysId=" +
          obj.ysId +
          "&channelNo=" +
          obj.channelNo
      );
    },
    // 翻页
    projectSizeChange(val) {
      that.$set(that.pjData, "size", val);
      that.$set(that.pjData, "current", 1);
      that.getProjectPage();
    },
    projectCurrentChange(val) {
      that.$set(that.pjData, "current", val);
      that.getProjectPage();
    },
  },
  beforeDestroy() {
    window.clearInterval(that.itv)
    that.itv=null
  }
};
</script>

<style scoped>
  .el-icon-search{
    font-size: 20px;
    border-left: 1px solid #dcdfe6;
    cursor: pointer;
  }
.iot-person {
  box-sizing: border-box;
}
.project-item {
  width: 23%;
  height: 100px;
  margin: 10px;
  border: 1px solid #ebeef5;
  border-radius: 3px;
  display: flex;
  cursor: pointer;
  background-color: #ffffff;
  float: left;
  box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);
  align-items: center;
}
.project-item:hover {
  box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);
}
.project-item-image {
  /* width: 120px; */
  height: 70%;
  display: flex;
  flex: 1;
  text-align: center;
  justify-content: center;
  /* padding: 10px; */
}
.project-item-image img,
.project-item-image >>> .el-image {
  height: 90%;
}
.project-item-words {
  width: 65%;
  height: 100%;
  margin-top: 35px;
  /* flex-direction: column;
  align-items: flex-start; */
}
.project-item-words h4,
.project-item-words p {
  margin-block-start: 0.5em;
  margin-block-end: 0.5em;
}
.project-item-words p {
  word-break: break-all;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  font-size: 13px;
}
.info-body {
  width: 100%;
  height: 680px;
}
.page {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}
.detailDialog >>> .el-dialog {
  border-radius: 5px;
}
.detailDialog >>> .el-dialog__body {
  padding: 0;
}
.dtuSignalTitle {
  box-sizing: border-box;
  padding: 0 96px;
}
#dtuSignal {
  width: 1440px;
  height: 581px;
}
.dtus_body {
  width: 100%;
  height: 100%;
  background: #fff;
}
.dtus {
  width: 300px;
}
.btn {
  width: 86px;

  padding: 0 20px;
  position: absolute;
  bottom: 0px;
  right: 0;
}
.iot-project-status {
  float: right;
  width: 40px;
  height: 40px;
  line-height: 40px;
  border-radius: 20px;
  text-align: center;
  font-size: 14px;
  color: #ffffff;
  margin-top: -10px;
  margin-right: 10px;
  cursor: pointer;
  transform: scale(0.75, 0.75);
}
p img {
  width: 30px;
  height: 30px;
  float: right;
  margin: -5px 10px 5px 0;
  cursor: pointer;
}
.editNum {
  color: rgb(64, 158, 255);
  cursor: pointer;
  text-decoration: underline;
}
.select {
  font-size: 16px;
  font-weight: bold;
  color: rgb(30, 170, 57);
}

.title {
  margin-bottom: 20px;
  width: 100%;
  /* display: flex;
  justify-content: space-between; */
  
}
.el-select,
.el-input,
.el-input-number {
  width: 250px;
}
.filterCss {
  filter: grayscale(90%);
}
.servers {
  text-align: center;
  font-size: 20px;
  font-weight: bold;
  color: red;
  margin-top: 20px;
}
</style>
